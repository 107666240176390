<template>
  <div>
    <div class="container-top" v-if="order">
      <div class="pc container padding-top-60 position-relative">
        <div class="h5 main" style="padding-bottom:24px">결제하기</div>

        <div class="flex-align margin-bottom-56" style="gap:16px">
          <img class="img-product"
               :src="order.product.img" :alt="order.product.name">
          <div>
            <div class="subtitle5 main">{{ order.product.name }}</div>
            <div class="body4 sub3">추가 개발 수정 요청</div>
          </div>
        </div>

        <div class="body0-bold main account-price-title">결제 금액</div>
        <div>
          <div class="item-account">
            <div class="subtitle5 sub2">추가 수정 견적 금액</div>
            <div class="flex-end" style="gap:12px">
              <div class="body2-bold main">{{ order.price|currencyNum }}<span class="body4">원</span></div>
            </div>
          </div>
          <div class="item-account">
            <div class="subtitle4 main">총 결제금액</div>
            <div class="h5 main">{{ order.price|currencyNum }}<span class="body2-medium sub">원</span></div>
          </div>
        </div>

        <div class="body0-bold main account-price-title" style="margin-top:76px">결제 방법</div>
        <div class="flex-center" style="margin:48px 0">
          <billing-form  style="width:480px" ref="form"></billing-form>
        </div>
        <lp-check-box-array :value.sync="agree" :items="checkItem"></lp-check-box-array>
        <div class="flex-center" style="margin-top:80px">
          <button class="button is-gray" style="width:242px;margin-right:8px" @click="clickPrev">이전</button>
          <button class="button is-primary" style="width:242px;margin-left:8px" @click="clickAccount">결제하기</button>
        </div>
      </div>
      <div class="mobile">
        <div class="mo-container">
          <div class="h8 main" style="padding-bottom:16px">결제하기</div>
          <div class="flex-align" style="padding:12px 16px;gap:12px">
            <img class="img-product"
                 :src="order.product.img" :alt="order.product.name">
            <div>
              <div class="body4-medium main">{{ order.product.name }}</div>
              <div class="body5 sub3">추가 개발 수정 요청</div>
            </div>
          </div>
        </div>
        <div class="mo-container">
          <div class="h8 main" style="padding-bottom:16px">결제 금액</div>
          <div class="flex lp-divider-gray1 margin-bottom-12"
               style="flex-direction: column;gap:12px;padding-bottom:12px">
            <div class="flex-between">
              <div class="body4 sub">추가 수정 견적 금액</div>
              <div>
                <span class="body4-bold">{{ order.price | currencyNum }}</span>
                <span class="body4 main">원</span>
              </div>
            </div>
          </div>
          <div class="flex-between">
            <div class="body4-medium main">총 결제금액</div>
            <div>
              <span class="h7 main">{{ order.price | currencyNum }}</span>
              <span class="body2-medium sub">원</span>
            </div>
          </div>
        </div>
        <div class="mo-container">
          <div class="h8 main" style="padding-bottom:16px">결제 방법</div>
          <billing-form ref="mform"></billing-form>
          <lp-check-box-array style="margin-top:16px"
                              :value.sync="agree" :items="checkItem"></lp-check-box-array>
          <button class="button is-primary margin-top-16" style="height:48px;width:100%" @click="clickAccount">결제하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BillingForm from "../module/BillingForm";
  import LpCheckBoxArray from "../component/LpCheckBoxArray";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  export default {
    name: "SkinInquiryAccount",
    components: {LpCheckBoxArray, BillingForm},
    mixins: [
      UserAPIMixin
    ],
    data() {
      return {
        agree: false,
        checkItem: [
          {
            label: '구매 정보 및 결제 내역을 확인했으며, 이에 동의합니다.',
            labelStyle: {
              color: '#4f4f4f',
              fontSize: '15px'
            }
          }
        ]
      }
    },
    computed: {
      order() {
        return this.$store.getters.basket;
      },
      isMobile() {
        return window.innerWidth < 1024;
      },
      billingForm() {
        return this.isMobile ? this.$refs.mform : this.$refs.form;
      },
    },
    methods: {
      clickPrev() {
        this.$router.back();
      },
      validate() {
        return !(Object.keys(this.billingForm.value).some(key => this.billingForm.value[key] === ''));
      },
      async clickAccount() {
        if(!this.agree) {
          this.toast('동의 후 진행해주세요');
          return;
        }

        let basket = this.cloneItem(this.order);

        if(this.billingForm.selectedBill) {
          basket.bill = this.billingForm.selectedBill.id;
        } else {
          if(!this.validate()) {
            this.toast('카드 정보를 제대로 입력해주세요.');
            return;
          }
          basket.credit = this.billingForm.value;
        }
        basket.account = true;
        this.setLoading();
        this.request.user.patch(`launchpack/v1/skin_inquiry/${basket.id}`, basket).then(res => {
          this.clearLoading();
          if(res.status === 200) {
            basket.credit = res.data.credit;
            this.$store.commit('clearBasket');
            this.$store.commit('setAccountDone', basket);
            this.routerPush('skin_inquiry_account_done');
          }
        }).catch(()=>{
          this.clearLoading();
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .account-price-title
    border-top 1px solid $main
    padding 16px 0
    border-bottom 1px solid $gray2

  .item-account
    display flex
    justify-content space-between
    align-content center
    padding 24px 0
    border-bottom 1px solid $gray1
  .item-account:last-child
    border-bottom 0

  .circle-gray
    width 3px
    height 3px
    background-color $gray1
    border-radius 100%

  .mo-container
    padding 24px 16px
    border-bottom 8px solid $gray2
  .mo-container:first-child
    border-bottom 1px solid $gray2
  .mo-container:last-child
    border-bottom 0

  .pc
    .mobile
    .img-product
      width 140px
      height 105px
      border-radius 6px
      object-fit cover

  .mobile
    .img-product
      width 94px
      height 70px
      border-radius 6px
      object-fit cover

</style>
